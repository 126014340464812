
$redcolor:#E2062C;

nav a {margin: 15px}

tbody.logtable tr.logentrymeta td {
    border-bottom: 0px;
}

tbody.logtable tr.pri5 {
    background-color: rgba($redcolor, .9);
}
tbody.logtable tr.pri4 {
    background-color: rgba($redcolor, .6);
}
tbody.logtable tr.pri3 {
    background-color: rgba($redcolor, .4);
}
tbody.logtable tr.pri2 {
    background-color: rgba($redcolor, .2);
}
tbody.logtable tr.Error td, tbody.logtable tr.error td {
    font-weight: bold;
}
tbody.logtable tr.logentrymessage td {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: monospace;
    overflow-x: auto;
}

.paginationsection input {
	margin-left: .5rem;
	margin-right: .5rem;
}
.filtersection {
    display:flex;
    justify-content: end;

    .filters {
       display: flex;
       justify-content: space-between;

       select {
           width: 8rem;
           margin-left: .5rem;
           margin-right: .5rem;
       }
    }
}

.modem-tabs + .tab-content  {
    margin-top: 3rem;
}
